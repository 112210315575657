.features {
  max-width: 1160px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 300px;
  margin: 30px auto;
  border-bottom: 1px solid silver;
}
.features > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  text-align: center;

  padding: 10px;
}

.features > div > * {
  margin-bottom: 0.3em;
}
.features > div > p {
  font-size: larger;
  color: #777373;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .features {
    flex-direction: column;
    align-items: center;
  }
  .features > div {
    width: 100%;
  }
  .features > div > p {
    font-size: medium;
  }
}