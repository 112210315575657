:root {
  --border-radius: 5px;
  --hover-brightness: 1.2;
}

.hero {
  width: 100%;
  min-height: 530px;
  border: 1px silver solid;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/model.jpg");

  /* Set a specific height */
  height: 50%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.hero-text {
  text-align: center;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-transform: capitalize;
}
.hero-text > * {
  margin-bottom: 0.2em;
}
button {
  border-radius: var(--border-radius);
  display: inline-block;
  font-size: medium;
  font-weight: bold;
  margin: 0.5rem 0 0.5rem 0;
  padding: 1rem 2rem;
  background: #c4fa6c;
  border: none;
}
button:hover {
  cursor: pointer;
  filter: brightness(var(--hover-brightness));
}
button > a, button a:visited {
  color: black;
  text-decoration: none;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .hero-text {
    top: 76%
  }
  .hero-text h6 {
    font-size: 1.1em;
  }
  .hero-text h1 {
    font-size: 2em;
  }
}