@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;600&family=Tenor+Sans&display=swap);
:root {
  --border-radius: 5px;
  --hover-brightness: 1.2;
}

.hero {
  width: 100%;
  min-height: 530px;
  border: 1px silver solid;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url(/static/media/model.467dadb7.jpg);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/static/media/model.467dadb7.jpg);

  /* Set a specific height */
  height: 50%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.hero-text {
  text-align: center;
  position: absolute;
  top: 70%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  text-transform: capitalize;
}
.hero-text > * {
  margin-bottom: 0.2em;
}
button {
  border-radius: 5px;
  border-radius: var(--border-radius);
  display: inline-block;
  font-size: medium;
  font-weight: bold;
  margin: 0.5rem 0 0.5rem 0;
  padding: 1rem 2rem;
  background: #c4fa6c;
  border: none;
}
button:hover {
  cursor: pointer;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  -webkit-filter: brightness(var(--hover-brightness));
          filter: brightness(var(--hover-brightness));
}
button > a, button a:visited {
  color: black;
  text-decoration: none;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .hero-text {
    top: 76%
  }
  .hero-text h6 {
    font-size: 1.1em;
  }
  .hero-text h1 {
    font-size: 2em;
  }
}
.features {
  max-width: 1160px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 300px;
  margin: 30px auto;
  border-bottom: 1px solid silver;
}
.features > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  text-align: center;

  padding: 10px;
}

.features > div > * {
  margin-bottom: 0.3em;
}
.features > div > p {
  font-size: larger;
  color: #777373;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .features {
    flex-direction: column;
    align-items: center;
  }
  .features > div {
    width: 100%;
  }
  .features > div > p {
    font-size: medium;
  }
}
.tutorial {
  max-width: 1160px;
  min-height: 800px;
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}

.tutorial h4 {
  line-height: 0.2em;
}

.tutorial-block{
  display: flex;
  width: 100%;

}
.tutorial-block > * {
  line-height: 0;
}
.explain {
  width: 50%;
}
.explain ul {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.explain-item {
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
}
.explain-item > div {
  margin: 10px 0;
  padding: 10px;
}
.explain-item > div > p {
  line-height: 1em;
  line-height: 1.2em;
  margin: 5px;
  padding: 5px;

  color: #777373;
}
.winner {
  width: 50%;
  background-image:  url(/static/media/winner.c545a12f.jpg);
  min-height: 800px;
  /* Set a specific height */
  height: 50%;
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

}
.explain > div {
  display: flex;
  justify-content: center;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .winner{
    display: none;
  }
  .explain {
    width: 100%;
  }
  .explain-item {
    display: block;
  }

}
.newsletter{
  max-width: 1160px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid silver;
}
.newsletterInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.newsletterInfo h5 {
  margin-bottom: .2em;
}

.newsletterInfo p {
  font-size: larger;
  color: #777373;
}

.newsletter form {
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.email {
  border-color: aliceblue;
  width: 300px;
  height: 20px;
  padding: 5px;
  font-size: 1em;
  border-radius: 15px;
  outline: none;
  display: block;
  margin-bottom: 10px;
}

.button{
  font-size: medium;
  font-weight: bold;
  margin: 1.5rem 0 0.5rem 0;
  padding: 1rem 2rem;
  width: 150px;
  border-radius: 5px;
  background: #c4fa6c;
  border: none;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .newsletterInfo > p {
    font-size: medium;
  }
  .newsletterInfo > h5 {
    font-size: 1.4em;
  }
}
.App-header {
  width: 100vw;
  height: 5%;
  background: #FA6C7C;
  background: -webkit-gradient(linear, left top, right top, from(rgba(248, 90, 91, 1)), color-stop(50%, rgba(250, 108, 124, 1)));
  background: linear-gradient(90deg, rgba(248, 90, 91, 1) 0%, rgba(250, 108, 124, 1) 50%);
  display: flex;
  align-items: center;
  /*justify-content: center; */
  color: white;
  text-align: center;
  min-height: 90px;
}
.brand {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.brand a, brand a:visited {
  text-decoration: none;
  color: #fff;
}
.brand h1 {
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  font-size: 1.7em;
  line-height: 1.2em;
  margin-bottom: 0;
  font-family: 'Tenor Sans', sans-serif;

}
.brand  h5 {
  font-weight: normal;
  font-size: 1.2em;
  line-height: 1.2em;
  text-transform: capitalize;
  font-style: italic;
  margin-bottom: 0;
}
.navigation {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  font-size: large;
}
.navigation ul {
  width: 100%;
  list-style: none;
}
.navigation ul li {
  display: inline-block;
  margin: 20px;
}

.navigation ul li a, .navigation ul li a:visited {
  color: #fff;
}
.navBars{
  display: none;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  /* Styles */
  .App-header{
    max-height: 60px;
    min-height: 60px;
  }
  .navigation {
    display: none;
  }
  .brand{
    width: 100%;
  }
  .brand a h1 {
    letter-spacing: -0.9px;
  }
  .navBars {
    display: block;
    position: relative;
    top: 0;
    right: 5px;
  }
}

* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Manrope", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.App {
  width: 100%;
  height: 100vh;
}

main {
  width: 100%;
}
section.content {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
}
footer {
  min-height: 10vh;
  width: 100%;
  background: #000;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

 .App {
   max-width: 100vw;
   height: 100vh;
   overflow-x: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  letter-spacing: .1em;
  margin-bottom: 2.0rem;
  margin-top: 0;
}

h1 {
  font-size: 4.6rem;
  line-height: 1.2;
}

h2 {
  font-size: 3.6rem;
  line-height: 1.25;
}

h3 {
  font-size: 2.8rem;
  line-height: 1.3;
}

h4 {
  font-size: 2.2rem;
  letter-spacing: -.08rem;
  line-height: 1.35;
}

h5 {
  font-size: 1.8rem;
  letter-spacing: -.05rem;
  line-height: 1.5;
}

h6 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.4;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  h1 {
    font-size: 2rem;
    line-height: 1.2;
  }

  h2 {
    font-size: 1.6rem;
    line-height: 1.25;
  }

  h3 {
    font-size: 1.4rem;
    line-height: 1.3;
  }

  h4 {
    font-size: 1.3rem;
    letter-spacing: -.08rem;
    line-height: 1.35;
  }

  h5 {
    font-size: 1.2rem;
    letter-spacing: -.05rem;
    line-height: 1.5;
  }

  h6 {
    font-size: 1.1rem;
    letter-spacing: 0;
    line-height: 1.4;
  }
  p {
    font-size: 0.9;
  }

}
