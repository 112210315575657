.App-header {
  width: 100vw;
  height: 5%;
  background: #FA6C7C;
  background: linear-gradient(90deg, rgba(248, 90, 91, 1) 0%, rgba(250, 108, 124, 1) 50%);
  display: flex;
  align-items: center;
  /*justify-content: center; */
  color: white;
  text-align: center;
  min-height: 90px;
}
.brand {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.brand a, brand a:visited {
  text-decoration: none;
  color: #fff;
}
.brand h1 {
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  font-size: 1.7em;
  line-height: 1.2em;
  margin-bottom: 0;
  font-family: 'Tenor Sans', sans-serif;

}
.brand  h5 {
  font-weight: normal;
  font-size: 1.2em;
  line-height: 1.2em;
  text-transform: capitalize;
  font-style: italic;
  margin-bottom: 0;
}
.navigation {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  font-size: large;
}
.navigation ul {
  width: 100%;
  list-style: none;
}
.navigation ul li {
  display: inline-block;
  margin: 20px;
}

.navigation ul li a, .navigation ul li a:visited {
  color: #fff;
}
.navBars{
  display: none;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  /* Styles */
  .App-header{
    max-height: 60px;
    min-height: 60px;
  }
  .navigation {
    display: none;
  }
  .brand{
    width: 100%;
  }
  .brand a h1 {
    letter-spacing: -0.9px;
  }
  .navBars {
    display: block;
    position: relative;
    top: 0;
    right: 5px;
  }
}
