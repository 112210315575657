@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;600&family=Tenor+Sans&display=swap');
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Manrope", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.App {
  width: 100%;
  height: 100vh;
}

main {
  width: 100%;
}
section.content {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
}
footer {
  min-height: 10vh;
  width: 100%;
  background: #000;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

 .App {
   max-width: 100vw;
   height: 100vh;
   overflow-x: hidden;
  }
}
