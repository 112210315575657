.tutorial {
  max-width: 1160px;
  min-height: 800px;
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}

.tutorial h4 {
  line-height: 0.2em;
}

.tutorial-block{
  display: flex;
  width: 100%;

}
.tutorial-block > * {
  line-height: 0;
}
.explain {
  width: 50%;
}
.explain ul {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.explain-item {
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
}
.explain-item > div {
  margin: 10px 0;
  padding: 10px;
}
.explain-item > div > p {
  line-height: 1em;
  line-height: 1.2em;
  margin: 5px;
  padding: 5px;

  color: #777373;
}
.winner {
  width: 50%;
  background-image:  url("../images/winner.jpg");
  min-height: 800px;
  /* Set a specific height */
  height: 50%;
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

}
.explain > div {
  display: flex;
  justify-content: center;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .winner{
    display: none;
  }
  .explain {
    width: 100%;
  }
  .explain-item {
    display: block;
  }

}