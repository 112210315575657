.newsletter{
  max-width: 1160px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid silver;
}
.newsletterInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.newsletterInfo h5 {
  margin-bottom: .2em;
}

.newsletterInfo p {
  font-size: larger;
  color: #777373;
}

.newsletter form {
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.email {
  border-color: aliceblue;
  width: 300px;
  height: 20px;
  padding: 5px;
  font-size: 1em;
  border-radius: 15px;
  outline: none;
  display: block;
  margin-bottom: 10px;
}

.button{
  font-size: medium;
  font-weight: bold;
  margin: 1.5rem 0 0.5rem 0;
  padding: 1rem 2rem;
  width: 150px;
  border-radius: 5px;
  background: #c4fa6c;
  border: none;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .newsletterInfo > p {
    font-size: medium;
  }
  .newsletterInfo > h5 {
    font-size: 1.4em;
  }
}